import React, { useEffect, useState, useReducer, useMemo } from "react";
import useAxios from "@use-hooks/axios";
import axios from "axios";
import {
  Button,
  Classes,
  Dialog,
  Drawer,
  Icon,
  Tab,
  Tabs,
  Spinner,
} from "@blueprintjs/core";
import { useInterval } from "../../utils/Hooks";
import { ResultSummaryView, SummaryViewLegend } from "../summary/ResultSummary";
import {
  JOB_STATUS,
  DEFAULT_EC_FILTERING_SETTINGS,
  DEFAULT_PARAMS,
  MAX_EC_RANK_THRESHOLD,
  API_POLLING_INTERVAL,
} from "../../utils/Constants";
import { AlignmentPanel } from "./Alignment";
import { DownloadsPanel } from "./Downloads";
import { ParametersPanel } from "./Parameters";
import { FoldingPanel } from "./Folding";
import StronglyCoupledSitesPanel from "./StronglyCoupledSites";
import EvolutionaryCouplingsPanel from "./EvolutionaryCouplings";
import MutationPanel from "./Mutations";
import {
  CustomStructureSubsettingsPanel,
  CustomStructureSubsettingsReducer,
  PairFilteringSubsettingsPanel,
  PairFilteringSubsettingsReducer,
  CustomMutationSubsettingsPanel,
  PanelSelectionSubsettingsPanel,
  ContactDistanceThresholdSubsettingPanel,
} from "./SettingsPanels";
import {
  transformJobgroupData,
  apiServerAddBaseUrl,
  createTargetSequenceMap,
  apiExperimentalStructuresQuery,
  apiSubmitCustomMutationPrediction,
} from "../../utils/Api";

// internal computation helpers for ECs
import {
  filterCouplings,
  computeCumulativeCoupling,
  addConservation,
} from "./../../utils/Couplings";
import {
  pollCustomMutationPredictions,
  CustomMutationPredictionReducer,
  EMPTY_CUSTOM_MUTATION_SET,
} from "./../../utils/Mutations";

import { makeDataFrame, TabWrapper, almostEqual } from "./../../utils/Helpers";
import {
  gatherAvailableStructures,
  updateStructurePool,
  updateDistanceMapPool,
  mergeDistanceMapsPerPanel,
  getContactsFromDistanceMap,
  initializePools,
  extractPoolStructures,
  removeInvalidStructures,
  StructureSelectionReducer,
  StructureLoadingReducer,
  EMPTY_STRUCTURE_POOL,
  DEFAULT_STRUCTURE_CLASS_ORDER,
  FOLDING_PANEL_STRUCTURE_CLASS_ORDER,
  EXAMPLE_POOLS,
  DISTANCE_MAP_MODE,
  SECONDARY_STRUCTURE_MODE,
  STRUCTURE_STATUS,
  mergeSecStructPerPanel,
  STRUCTURE_CLASSES,
} from "./../../utils/Structures";

/*
  Individual compute job result main display

  TODO: allow the following use cases:
  * user-uploaded 3D structure (e.g. cryo model)
  * user-uploaded mutation effects for prediction
*/
export const JobResult = ({ jobGroup, job, jobGroupData, predictedStructureUrl }) => {
  // job selection window open or not?
  const [overviewOpen, setOverviewOpen] = useState(false);

  // show settings drawer or not?
  const [settingsOpen, setSettingsOpen] = useState(false);

  // selected tab
  const [selectedTab, setSelectedTab] = useState("tab_couplings");

  // extract info for selected subjob (safe to access since we know job exists)
  let jobData = jobGroupData.jobs.filter((j) => j.id === job)[0];

  const hasBackendModelStructures = jobData.result_summary.has_model_structures;
  const hasFrontendModelStructure = predictedStructureUrl !== null;

  // AF modification hack: mutate predicted structure information into API results
  if (hasBackendModelStructures) {
    // inject model structures as predicted structures
    jobData.result_summary.has_predicted_structures = true;
    jobData.links.predicted_structures = jobData.links.model_structures;
  } else if (hasFrontendModelStructure) {
    // fallback - use model retrieved by identifier matching on frontend
    jobData.result_summary.has_predicted_structures = true;
    jobData.links.predicted_structures = "placeholder";
  } else {
    jobData.result_summary.has_predicted_structures = false;
    jobData.links.predicted_structures = null;
  }
  // end of AF modification

  // if folding not run, cannot display results
  const hasPredictedStructures = jobData.result_summary.has_predicted_structures;
  const hasExperimentalStructures =
    jobData.result_summary.has_experimental_structures;
  const hasAnyStructure = hasPredictedStructures || hasExperimentalStructures;

  // until properly verified, do not show mean-field mutation effects
  const deactivateMutations =
    jobData.config_summary.couplings_protocol !== "standard";

  // extract target sequence dictionary
  const targetSequence = createTargetSequenceMap(jobGroupData.target);

  /*
    Panel-specific settings
  */
  // panel settings for couplings tab
  const [couplingsShowContactMap, setCouplingsShowContactMap] = useState(true);
  const [couplingsShowViewer, setCouplingsShowViewer] = useState(
    hasExperimentalStructures
  );
  const [couplingsShowNetwork, setCouplingsShowNetwork] = useState(
    !hasExperimentalStructures
  );
  const [couplingsShowTable, setCouplingsShowTable] = useState(true);

  // panel settings for sites tab
  // TODO: use reducer and joint state when refactoring render function for selection panel
  const [sitesShowNetwork, setSitesShowNetwork] = useState(true);
  const [sitesShowTable, setSitesShowTable] = useState(true);
  const [sitesShowViewer, setSitesShowViewer] = useState(
    hasExperimentalStructures
  );

  // mutation panel
  const [mutationsShowMatrix, setMutationsShowMatrix] = useState(true);
  const [mutationsShowViewer, setMutationsShowViewer] = useState(
    hasExperimentalStructures
  );

  // folding panel
  const [foldShowTable, setFoldShowTable] = useState(true);
  const [foldShowViewer, setFoldShowViewer] = useState(hasAnyStructure);

  /*
    Settings across panels
  */
  const [ecFilters, dispatchEcFilters] = useReducer(
    PairFilteringSubsettingsReducer,
    DEFAULT_EC_FILTERING_SETTINGS
  );

  const [contactDistanceThreshold, setContactDistanceThreshold] = useState(
    DEFAULT_PARAMS.compareDistanceThreshold
  );

  /*
    User settings for contact/distance maps in EC panel
  */
  const [contactSettings, setContactSettings] = useState({
    // only display experimental contacts by default if available
    distanceMapMode: hasExperimentalStructures
      ? DISTANCE_MAP_MODE.EXPERIMENTAL_ALL
      // : DISTANCE_MAP_MODE.NO_STRUCTURE,
      : DISTANCE_MAP_MODE.SYNC_WITH_VIEWER,
    // distanceMapMode: DISTANCE_MAP_MODE.EXPERIMENTAL_ALL,
    selectedExperimentalStructures: null,
    // shownSecondaryStructure: DISTANCE_MAP_MODE.SYNC_WITH_VIEWER
    // shownSecondaryStructure: SECONDARY_STRUCTURE_MODE.PREDICTED
    shownSecondaryStructure: hasAnyStructure
      ? hasExperimentalStructures
        ? SECONDARY_STRUCTURE_MODE.EXPERIMENTAL
        // : SECONDARY_STRUCTURE_MODE.PREDICTED
        : SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER
      : SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER,
  });

  /*
    All data fetching/storing for particular subjob

    TODO: turn repetitious data loading code into own hoook?
  */

  /*
    Evolutionary Couplings
  */
  // data fetching, depending on jobGroup and job
  const [couplingsData, setCouplingsData] = useState({
    data: null,
    table: null,
    loading: null,
    error: null,
    refetch: null,
  });

  const {
    response: responseCouplings,
    loading: loadingCouplings,
    error: errorCouplings,
    reFetch: refetchCouplings,
  } = useAxios({
    url: apiServerAddBaseUrl(jobData.links.couplings), // TODO: remove
    trigger: [jobGroup, job],
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      setCouplingsData({
        // only update data if we had a response
        data: response && response.data ? response.data : null,

        // also store version in dataframe for better access
        table:
          response && response.data && response.data.pairs
            ? makeDataFrame(response.data.pairs)
            : null,

        error: error,
        loading: loadingCouplings,
        refetch: refetchCouplings,
      });
    },
  });
  // console.log("### LOADING COUPLINGS", loadingCouplings);

  /*
    Alignment data (excluding actual alignment file)
  */
  const [alignmentData, setAlignmentData] = useState({
    data: null,
    loading: null,
    error: null,
    refetch: null,
  });

  const {
    response: responseAlignment,
    loading: loadingAlignment,
    error: errorAlignment,
    reFetch: refetchAlignment,
  } = useAxios({
    url: apiServerAddBaseUrl(jobData.links.alignment),
    trigger: [jobGroup, job],
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      setAlignmentData({
        data: response && response.data ? response.data : null,
        frequencies:
          response && response.data && response.data.frequencies
            ? makeDataFrame(response.data.frequencies)
            : null,
        identitites:
          response && response.data && response.data.identitites
            ? makeDataFrame(response.data.identitites)
            : null,
        error: error,
        loading: loadingAlignment,
        refetch: refetchAlignment,
      });
    },
  });

  /*
    Download data (all links to result files)
  */
  const [downloadsData, setDownloadsData] = useState({
    data: null,
    loading: null,
    error: null,
    refetch: null,
  });

  const {
    response: responseDownloads,
    loading: loadingDownloads,
    error: errorDownloads,
    reFetch: refetchDownloads,
  } = useAxios({
    url: apiServerAddBaseUrl(jobData.links.downloads),
    trigger: [jobGroup, job],
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      setDownloadsData({
        data: response && response.data ? response.data : null,
        error: error,
        loading: loadingDownloads,
        refetch: refetchDownloads,
      });
    },
  });

  /*
    Mutation effects
  */
  // TODO: store reFetch but don't overwrite the one from couplings loading
  const [mutationData, setMutationData] = useState({
    data: null,
    loading: null,
    error: null,
    refetch: null,
  });

  const {
    response: responseMutations,
    loading: loadingMutations,
    error: errorMutations,
    reFetch: refetchMutations,
  } = useAxios({
    url: apiServerAddBaseUrl(jobData.links.mutations),
    trigger: [jobGroup, job],
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      setMutationData({
        data: response && response.data ? response.data : null,
        table:
          response && response.data && response.data.mutation_predictions
            ? makeDataFrame(response.data.mutation_predictions)
            : null,
        error: error,
        loading: loadingMutations,
        refetch: refetchMutations,
      });
    },
  });

  /*
    User-predicted mutation effects
  */
  const [customMutations, dispatchCustomMutations] = useReducer(
    CustomMutationPredictionReducer,
    EMPTY_CUSTOM_MUTATION_SET
  );

  // regular polling of results after posting prediction job
  useInterval(() => {
    pollCustomMutationPredictions(customMutations, dispatchCustomMutations);
  }, API_POLLING_INTERVAL);

  /*
    Experimental structure data (meta information, contacts, links to 3D coords)
  */
  // initial loading state (all structures, default cutoff)
  const [experimentalStructureData, setExperimentalStructureData] = useState({
    data: null,
    loading: null,
    error: null,
    refetch: null,
    pairDistances: null,
  });

  // for custom updates (selected structures, distance cutoff), overrides initial state
  // if custom parameters are used
  const [
    experimentalStructureDataCustom,
    setExperimentalStructureDataCustom,
  ] = useState({
    data: null,
    loading: null,
    error: null,
    refetch: null,
    pairDistances: null,
  });

  // Default experimental structure info loader (includes contacts at default cutoff for all structures);
  // will only be loaded once in the beginning and kept steady to avoid e.g. experimental structures
  // being dropped further down
  const prepareStructureState = (response, error, loadingState, refetch) => ({
    data: response && response.data ? response.data : null,
    monomerContacts:
      response &&
      response.data &&
      response.data.contacts &&
      response.data.contacts.monomer
        ? makeDataFrame(response.data.contacts.monomer)
        : null,
    multimerContacts:
      response &&
      response.data &&
      response.data.contacts &&
      response.data.contacts.multimer
        ? makeDataFrame(response.data.contacts.multimer)
        : null,
    secondaryStructure:
      response && response.data && response.data.secondary_structure
        ? makeDataFrame(response.data.secondary_structure).orderBy(
            (row) => row.i
          )
        : null,
    pairDistances:
      response && response.data && response.data.couplings_pair_distances
        ? makeDataFrame(response.data.couplings_pair_distances)
        : null,
    error: error,
    loading: loadingState,
    refetch: refetch,
  });

  const {
    response: responseExperimentalStructures,
    loading: loadingExperimentalStructures,
    error: errorExperimentalStructures,
    reFetch: refetchExperimentalStructures,
  } = useAxios({
    url: apiServerAddBaseUrl(
      apiExperimentalStructuresQuery(
        jobData.links.experimental_structures,
        DEFAULT_PARAMS.compareDistanceThreshold
      )
    ),
    trigger: [jobGroup, job, DEFAULT_PARAMS.compareDistanceThreshold],
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      setExperimentalStructureData(
        prepareStructureState(
          response,
          error,
          loadingExperimentalStructures,
          refetchExperimentalStructures
        )
      );
    },
  });

  // only run custom experimental structure query if selected subset of structures,
  // or non-default contact distance threshold
  const useCustomExperimentalStructure =
    contactSettings.distanceMapMode === DISTANCE_MAP_MODE.EXPERIMENTAL_SUBSET ||
    (contactSettings.distanceMapMode === DISTANCE_MAP_MODE.EXPERIMENTAL_ALL &&
      !almostEqual(
        contactDistanceThreshold,
        DEFAULT_PARAMS.compareDistanceThreshold
      ));

  // note that selectedExperimentalStructures is blocked from being empty by GUI;
  // only set this if respective contact mode is actually selected
  const customStructureSelection =
    contactSettings.selectedExperimentalStructures &&
    contactSettings.selectedExperimentalStructures.count() > 0
      ? contactSettings.selectedExperimentalStructures.toJS().map((v) => v.id)
      : null;

  // Experimental structure loading for custom structure selections and distance cutoffs
  const {
    response: responseExperimentalStructuresCustom,
    loading: loadingExperimentalStructuresCustom,
    error: errorExperimentalStructuresCustom,
    reFetch: refetchExperimentalStructuresCustom,
  } = useAxios({
    url: apiServerAddBaseUrl(
      apiExperimentalStructuresQuery(
        jobData.links.experimental_structures,
        contactDistanceThreshold,
        customStructureSelection
      )
    ),
    trigger: [
      jobGroup,
      job,
      contactDistanceThreshold,
      JSON.stringify(customStructureSelection),
      contactSettings.distanceMapMode,
    ],
    // only trigger request if subset of structures or custom distance cutoff
    forceDispatchEffect: () => useCustomExperimentalStructure,
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      setExperimentalStructureDataCustom(
        prepareStructureState(
          response,
          error,
          loadingExperimentalStructuresCustom,
          refetchExperimentalStructuresCustom
        )
      );
    },
  });

  /*
    Predicted structure data (meta information, ranking, links to 3D coords)
  */
  const [predictedStructureData, setPredictedStructureData] = useState({
    data: null,
    loading: null,
    error: null,
    refetch: null,
  });

  /* original structure prediction model loading below */
  
  /* modified to load one or more external models from API */
  const {
    respose: responsePredictedStructures,
    loading: loadingPredictedStructures,
    error: errorPredictedStructures,
    reFetch: refetchPredictedStructures,
  } = useAxios({
    url: apiServerAddBaseUrl(jobData.links.predicted_structures),
    trigger: [jobGroup, job],
    // only dispatch query if there is actually predicted structure information
    // forceDispatchEffect: () => jobData.links.predicted_structures,
    forceDispatchEffect: () => hasBackendModelStructures,
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      // turn API response into format originally returned by structure prediction endpoint
      const remapModels = (responseData) => ({
          models: responseData.structures.map(
            (s, idx) => ({
              id: s.id,
              model_name: s.pdb_id.replace("-model_v4", ""),
              model_name_short: s.pdb_id.replace("-model_v4", ""),
              model_rank: idx + 1,
              ranking_score: 1,
              links: {
                coords: s.links.coords,
              },
              cluster_index: 1,
              cluster_size: 1,
              comparison_to_experimental: null,
            })
          ),
      })

      // console.log("PREDICTED STRUCTURES CUSTOM HANDLER", error, response);
      setPredictedStructureData({
        data: response && response.data ? remapModels(response.data) : null,
        secondaryStructure: null,
          // response && response.data && response.data.secondary_structure
          //   ? makeDataFrame(response.data.secondary_structure).orderBy(
          //       (row) => row.i
          //     )
          //   : null,
        error: error,
        loading: loadingPredictedStructures,
        refetch: refetchPredictedStructures,
      });
    },
  });

  /* replacement hack to inject external structure prediction - 
  this remains as a second option when backend data for external models not available 
  (e.g. legacy jobs) */

  useEffect(() => {
    // model information delivered by backend takes precendence
    if (hasBackendModelStructures)
      return;

    // const loadingPredictedStructures = false;
    // derive structure name from file name
    const structureName = predictedStructureUrl ? 
      predictedStructureUrl.split("/").slice(-1)[0].replace(".pdb", "") : 
      null;

    const predictedStructureDataPayLoad = hasFrontendModelStructure ? {
      data: {
        models: [{
          id: 0,
          model_name: structureName,
          model_name_short: structureName, 
          model_rank: 1,
          ranking_score: 1,
          links: {
            coords: predictedStructureUrl,
          },
          cluster_index: 1,
          cluster_size: 1,
          comparison_to_experimental: null,
        }],
        secondary_structure: null,  // note: this is needed so experimental structures are still loaded?
      },
      secondaryStructure: null,
      loading: false,
      error: null,
      refetch: null,
    } : {
      data: null,
      loading: false,
      error: null,
      refetch: null,
      secondary_structure: null,
    };

    // update structure data
    setPredictedStructureData(predictedStructureDataPayLoad);

  }, [predictedStructureUrl, hasBackendModelStructures, hasFrontendModelStructure])
  

  /* end of AF injection hack */

  /*
    Data derived from API-fetched data
  */

  /*
    Filtered ECs and cumulative coupling strength

    impose strict cutoff on maximum number of ECs to avoid
    overloading visualizations
  */
  const pairsFiltered = useMemo(() => {
    if (couplingsData.table) {
      // console.log("FILTERING TABLE");
      const filters = {
        minScore: ecFilters.useScoreFilter
          ? ecFilters.scoreFilterThreshold
          : null,
        minProbability: ecFilters.useProbabilityFilter
          ? ecFilters.probabilityFilterThreshold
          : null,
        maxRankFraction: ecFilters.useRankFilter
          ? ecFilters.rankFilterThreshold
          : MAX_EC_RANK_THRESHOLD,
        minSeqDist: ecFilters.useSeqDistFilter
          ? ecFilters.seqDistFilterThreshold
          : null,
        maxRank: null,
      };
      // console.log("FILTERS", filters);
      return filterCouplings(couplingsData.table, filters);
    } else {
      return null;
    }
  }, [couplingsData.table, ecFilters]);

  // Specifically filtered version for cumulative couplings
  // as no score may be < 0 (leaving these ECs in will
  // lead to problems with cytoscape due to missing corresponding
  // nodes)
  /*
  const pairsFilteredCumulative = useMemo(() => {
    if (pairsFiltered) {
      return pairsFiltered.where(row => row.score >= 0);
    } else {
      return null;
    }
  }, [pairsFiltered]);
  */

  // compute cumulative coupling strength
  const cumulativeCouplings = useMemo(() => {
    if (pairsFiltered) {
      // return computeCumulativeCoupling(pairsFilteredCumulative);
      // scores < 0 replaced in function, so no need to filter any more
      return computeCumulativeCoupling(pairsFiltered);
    } else {
      return null;
    }
  }, [pairsFiltered]);

  // add conservation info to couplings table
  const cumulativeCouplingsWithConservation = useMemo(() => {
    return addConservation(cumulativeCouplings, alignmentData.frequencies);
  }, [cumulativeCouplings, alignmentData.frequencies]);

  /*
    User-uploaded 3D structures
  */
  const [
    userStructures,
    dispatchUserStructures,
  ] = useReducer(CustomStructureSubsettingsReducer, { structures: [] });

  /*
    Integrate all structure information in one joint package

    Part 1: Available structures 
    Part 2: Selected structures in different structure "pools" (for different panels)
    Part 3: Structure pool
    Part 4: Structure loading upon selection
  */
  // Part 1: Gather available structures
  // (these change implicitly due to dependency on data loaded conditionally on subjob)
  const availableStructures = useMemo(
    () =>
      gatherAvailableStructures(
        experimentalStructureData,
        predictedStructureData,
        userStructures,
        loadingExperimentalStructures,
        loadingPredictedStructures
      ),
    [
      experimentalStructureData,
      predictedStructureData,
      userStructures,
      loadingExperimentalStructures,
      loadingPredictedStructures,
    ]
  );

  // Part 2: structure selection
  // (reducer that allows to select/unselect structures in different pools)
  // TODO: dispatchStructureSelection({pool: "contact_map", action: "set_structures", selection: [...]})
  const [selectedStructures, dispatchStructureSelection] = useReducer(
    StructureSelectionReducer,
    null // EXAMPLE_POOLS
  );

  // automatically deselect (user) structures if they get deleted/unavailable
  useEffect(
    () =>
      removeInvalidStructures(
        availableStructures,
        selectedStructures,
        dispatchStructureSelection
      ),
    [availableStructures, selectedStructures, dispatchStructureSelection]
  );

  // Part 3: holds actual structure data once loaded (and status information)
  const [structurePool, dispatchStructurePool] = useReducer(
    StructureLoadingReducer,
    EMPTY_STRUCTURE_POOL
  );

  // helper function to trigger reloading of failed structures
  const refetchFailedStructures = () =>
    dispatchStructurePool({ action: "removeFailedStructures" });

  // similar logic for distance maps (in separate object from structure pool for easier state update logic)
  const [distanceMapPool, dispatchDistanceMapPool] = useReducer(
    StructureLoadingReducer,
    EMPTY_STRUCTURE_POOL
  );

  // Trigger automatic loading of first shown structures
  useEffect(() => {
    // only trigger is selection is not yet initialized (this is sign to run initialization)
    if (!selectedStructures) {
      // only run once we have all info available on predicted and experimental structures
      if (
        availableStructures.get("experimentalIsFinal") &&
        (availableStructures.get("predictedIsFinal") ||
          !jobData.links.predicted_structures)
      ) {
        const poolDefaultSelections = initializePools(availableStructures, {
          // define order in which to try structure groups
          couplings: {
            order: DEFAULT_STRUCTURE_CLASS_ORDER,
            count: 1,
          },
          sites: {
            order: DEFAULT_STRUCTURE_CLASS_ORDER,
            count: 1,
          },
          mutations: {
            order: DEFAULT_STRUCTURE_CLASS_ORDER,
            count: 1,
          },
          fold: {
            order: FOLDING_PANEL_STRUCTURE_CLASS_ORDER,
            count: 2,
          },
        });

        dispatchStructureSelection({
          action: "setAll",
          selection: poolDefaultSelections,
        });
      }
    }
  }, [availableStructures, selectedStructures, jobData]);

  // Part 4: actual structure loading (based on data from part 2)
  useEffect(() => {
    if (selectedStructures) {
      updateStructurePool(
        availableStructures,
        selectedStructures,
        structurePool,
        dispatchStructurePool,
        jobGroup,
        job
      );
    }
  }, [
    availableStructures,
    selectedStructures,
    structurePool,
    dispatchStructurePool,
    jobGroup,
    job,
  ]);

  // also merge secondary structure from loaded structures
  const mergedSecondaryStructure = useMemo(() => {
    if (
      contactSettings.shownSecondaryStructure ===
      SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER
    ) {
      return mergeSecStructPerPanel(
        ["couplings"],
        structurePool,
        userStructures,
        selectedStructures,
        jobGroup,
        job
      );
    } else {
      return null;
    }
  }, [
    contactSettings.shownSecondaryStructure,
    structurePool,
    userStructures,
    selectedStructures,
    jobGroup,
    job,
  ]);

  // console.log("xxx SELECTED", mergedSecondaryStructure && mergedSecondaryStructure.get("couplings") ? mergedSecondaryStructure.get("couplings").toString() : null);

  // also trigger distance map computation for loaded structures
  useEffect(() => {
    // only trigger distance map computation if syncing is actually on
    if (
      contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER
    ) {
      updateDistanceMapPool(
        ["couplings"], //distanceMapPanels,
        selectedStructures,
        distanceMapPool,
        dispatchDistanceMapPool,
        structurePool,
        userStructures,
        jobGroup,
        job
      );
    }
  }, [
    // distanceMapPanels,
    selectedStructures,
    distanceMapPool,
    dispatchDistanceMapPool,
    structurePool,
    userStructures,
    jobGroup,
    job,
    contactSettings,
  ]);

  /*
    Merge distance maps; for simplicity, don't create this using useEffect for now
    even though may be computationally intensive
  */
  // simple proxy if available distmaps changed; this is based on assumption
  // that we only need one distance map merged for contacts panel
  const availableDistMapsHashCode = distanceMapPool
    .filter((v, k) => v.get("status") === STRUCTURE_STATUS.LOADED)
    .keySeq()
    .hashCode();

  const mergedDistanceMaps = useMemo(() => {
    if (
      contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER
    ) {
      // console.log("/// MERGE");
      return mergeDistanceMapsPerPanel(
        ["couplings"], //distanceMapPanels,
        selectedStructures,
        distanceMapPool,
        jobGroup,
        job
      );
    } else {
      return null;
    }
  }, [
    // note these are a fudge to only trigger computation when absolutely necessary
    contactSettings.distanceMapMode,
    availableDistMapsHashCode,
    jobGroup,
    job,
  ]);

  // derive contacts from merged distance maps (this will be Map panel -> distance map)
  const contactsFromDistanceMaps = useMemo(() => {
    if (
      contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER
    ) {
      // console.log("/// GET CONTACTS");
      return getContactsFromDistanceMap(
        mergedDistanceMaps,
        contactDistanceThreshold
      );
    } else {
      return null;
    }
  }, [
    contactSettings.distanceMapMode,
    mergedDistanceMaps,
    contactDistanceThreshold,
  ]);

  // get structure packages for different panels (based on pools)
  const structuresForPanels = useMemo(
    () =>
      selectedStructures && structurePool
        ? extractPoolStructures(
            selectedStructures,
            structurePool,
            userStructures,
            jobGroup,
            job
          )
        : null,
    [selectedStructures, structurePool, userStructures, jobGroup, job]
  );

  const loadingStructuresForDistmaps =
    contactSettings.distanceMapMode === DISTANCE_MAP_MODE.SYNC_WITH_VIEWER &&
    structuresForPanels &&
    structuresForPanels
      .get("couplings")
      .filter((v, k) => v.get("status") === STRUCTURE_STATUS.LOADING)
      .count() > 0;

  /*
  console.log(
    "/// EXPERIMENTAL CONTACTS",
    experimentalStructureData && experimentalStructureData.monomerContacts
      ? experimentalStructureData.monomerContacts.tail(5).toString()
      : null
  );*/

  // console.log("::: USER STRUCTURES", structuresForPanels ? structuresForPanels.get("couplings") : null);

  /*
    Resetting of state if job or subjob changes that does not get automatically reloaded
    (selections, loaded structures)
  */
  useEffect(() => {
    // reset structure selection
    dispatchStructureSelection({ action: "reset" });

    // TODO: reset selections
  }, [jobGroup, job]);

  // defined here for flexible placement exploration
  const settingsButton = (
    <Button
      minimal={true}
      outlined={true}
      large={true}
      onClick={() => setSettingsOpen(!settingsOpen)}
      disabled={
        selectedTab === "tab_download" ||
        selectedTab === "tab_model" ||
        selectedTab === "tab_align"
      }
      icon="cog"
      style={{marginLeft: "0.75em"}}
    >
      Settings
      {/* <Icon icon="cog" iconSize={32} /> */}
    </Button>
  );

  /*
    Rendering of job title and job selection window
  */
  const renderHeaderDialog = () => {
    // bring job group data into right format for summary view
    let transformedJobGroupData = transformJobgroupData(jobGroupData);

    // can safely access here since we know a valid subjob has
    // been selected (this is somewhat redundant with extraction above...)
    let subjobData = transformedJobGroupData.jobs.filter(
      (j) => j.id === job
    )[0];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "0.25em",
          // needed for Safari or otherwise flexbox gets squeezed together, leading to overlaps.
          // cf. https://stackoverflow.com/questions/57044598/height-of-flex-item-is-wrong-in-safari
          flexShrink: "0",
        }}
      >
        <h3
          className="bp3-heading"
          style={{ marginBottom: "0px", marginRight: "1em" }}
        >
          Results: {transformedJobGroupData.jobGroupName}{" "}
          <span className="bp3-text-disabled">|</span>{" "}
          {transformedJobGroupData.bitscore ? (
            "bitscore " + subjobData.threshold
          ) : (
            <span>
              E-value 10<sup>{"-" + subjobData.threshold}</sup>
            </span>
          )}
        </h3>
        <Button
          large={true}
          minimal={false}
          outlined={true}
          icon="timeline-bar-chart"
          onClick={() => setOverviewOpen(true)}
        >
          Result overview
        </Button>
        <Dialog
          isOpen={overviewOpen}
          onClose={() => setOverviewOpen(false)}
          lazy={false}
          title={"Result overview: " + transformedJobGroupData.jobGroupName}
          icon="timeline-bar-chart"
          usePortal={true}
          style={{ width: "750px", maxWidth: "95%" }}
        >
          {/* <div className={Classes.DIALOG_BODY}> */}
          <div style={{ margin: "2em" }}>
            <ResultSummaryView
              jobGroup={jobGroup}
              jobGroupData={transformedJobGroupData}
              selectedJob={job}
              handleClick={() => setOverviewOpen(false)}
            />
            <SummaryViewLegend />
          </div>
        </Dialog>
        {settingsButton}
      </div>
    );
  };

  /*
    Render job display depending if job has finished
    successfully, is still running, or failed/was terminated.
  */
  const renderJob = () => {
    // check if job is through already
    if (jobData.status === JOB_STATUS.DONE) {
      return (
        // <div style={{ overflowX: "auto" }}>
        <Tabs
          animate={false}
          id="ResultsTabs"
          vertical={false}
          renderActiveTabPanelOnly={false}
          selectedTabId={selectedTab}
          onChange={(newTabId, prevTabId, event) => setSelectedTab(newTabId)}
        >
          <Tab
            id="tab_couplings"
            title="Evolutionary couplings"
            panel={
              <TabWrapper id="tab_couplings" activeId={selectedTab}>
                <EvolutionaryCouplingsPanel
                  couplingsData={couplingsData}
                  targetSequence={targetSequence}
                  refetchCouplings={refetchCouplings}
                  pairsFilt={pairsFiltered}
                  cumulativeCouplings={cumulativeCouplings}
                  contactDistanceThreshold={contactDistanceThreshold}
                  mergedDistanceMap={
                    mergedDistanceMaps
                      ? mergedDistanceMaps.get("couplings")
                      : null
                  }
                  contactsFromDistanceMap={
                    contactsFromDistanceMaps
                      ? contactsFromDistanceMaps.get("couplings")
                      : null
                  }
                  // monomerContacts={monomerContacts} // TODO: move down
                  // multimerContacts={multimerContacts} // TODO: move down
                  hasExperimentalStructures={hasExperimentalStructures}
                  hasPredictedStructures={hasPredictedStructures}
                  contactSettings={contactSettings}
                  setContactSettings={setContactSettings}
                  // secondaryStructure={secondaryStructure}
                  experimentalStructureData={
                    useCustomExperimentalStructure
                      ? experimentalStructureDataCustom
                      : experimentalStructureData
                  }
                  predictedStructureData={predictedStructureData}
                  mergedSecondaryStructure={
                    mergedSecondaryStructure
                      ? mergedSecondaryStructure.get("couplings")
                      : null
                  }
                  jobGroup={jobGroup}
                  job={job}
                  jobData={jobData}
                  showContactMap={couplingsShowContactMap}
                  showViewer={couplingsShowViewer}
                  showNetwork={couplingsShowNetwork}
                  showTable={couplingsShowTable}
                  structures={
                    structuresForPanels
                      ? structuresForPanels.get("couplings")
                      : null
                  }
                  availableStructures={availableStructures}
                  dispatchStructureSelection={(selection) => {
                    // check if any predicted structures/models are selected, if so, switch 
                    // contact map mode to synced contacts and secondary structure
                    const predSelected = selection.filter(x => x.get("class") === STRUCTURE_CLASSES.PREDICTED).size > 0;
                    if (predSelected) {
                      setContactSettings({
                        distanceMapMode: DISTANCE_MAP_MODE.SYNC_WITH_VIEWER,
                        selectedExperimentalStructures: null,  // probably can leave that as null
                        shownSecondaryStructure: SECONDARY_STRUCTURE_MODE.SYNC_WITH_VIEWER,
                      })
                    }                   

                    dispatchStructureSelection({
                      action: "setPool",
                      pool: "couplings",
                      selection: selection,
                    });

                  }}
                  refetchFailedStructures={refetchFailedStructures}
                  isLoading={
                    loadingCouplings ||
                    loadingExperimentalStructures ||
                    loadingExperimentalStructuresCustom ||
                    loadingPredictedStructures ||
                    loadingStructuresForDistmaps
                  }
                />
              </TabWrapper>
            }
          />
          <Tab
            id="tab_sites"
            title="Strongly coupled sites"
            panel={
              <TabWrapper id="tab_sites" activeId={selectedTab}>
                <StronglyCoupledSitesPanel
                  couplingsData={couplingsData}
                  refetchCouplings={refetchCouplings}
                  // pairsFilt={pairsFilteredCumulative}
                  pairsFilt={pairsFiltered}
                  // cumulativeCouplings={cumulativeCouplings}
                  cumulativeCouplings={cumulativeCouplingsWithConservation}
                  structures={
                    structuresForPanels
                      ? structuresForPanels.get("sites")
                      : null
                  }
                  availableStructures={availableStructures}
                  dispatchStructureSelection={(selection) =>
                    dispatchStructureSelection({
                      action: "setPool",
                      pool: "sites",
                      selection: selection,
                    })
                  }
                  jobGroup={jobGroup}
                  job={job}
                  showSegments={false}
                  showNetwork={sitesShowNetwork}
                  showTable={sitesShowTable}
                  showViewer={sitesShowViewer}
                  refetchFailedStructures={refetchFailedStructures}
                />
              </TabWrapper>
            }
          />
          <Tab
            id="tab_mutations"
            title="Mutations"
            disabled={deactivateMutations}
            panel={
              <TabWrapper id="tab_mutations" activeId={selectedTab}>
                <MutationPanel
                  mutationData={mutationData}
                  refetchMutations={refetchMutations}
                  jobGroup={jobGroup}
                  job={job}
                  showSegments={false}
                  showMatrix={mutationsShowMatrix}
                  showViewer={mutationsShowViewer}
                  structures={
                    structuresForPanels
                      ? structuresForPanels.get("mutations")
                      : null
                  }
                  availableStructures={availableStructures}
                  dispatchStructureSelection={(selection) =>
                    dispatchStructureSelection({
                      action: "setPool",
                      pool: "mutations",
                      selection: selection,
                    })
                  }
                  // TODO: should check here that download link for mutations is actually available
                  mutationFileDownloadLink={
                    apiServerAddBaseUrl(jobData.links.downloads) +
                    "mutation_matrix_file"
                  }
                  refetchFailedStructures={refetchFailedStructures}
                />
              </TabWrapper>
            }
          />
          {/* <Tab
            id="tab_fold"
            title="Folding"
            disabled={!hasPredictedStructures}
            panel={
              <TabWrapper id="tab_fold" activeId={selectedTab}>
                <FoldingPanel
                  predictedStructureData={predictedStructureData}
                  targetSequence={targetSequence}
                  showViewer={foldShowViewer}
                  showTable={foldShowTable}
                  structures={
                    structuresForPanels ? structuresForPanels.get("fold") : null
                  }
                  availableStructures={availableStructures}
                  dispatchStructureSelection={(selection) =>
                    dispatchStructureSelection({
                      action: "setPool",
                      pool: "fold",
                      selection: selection,
                    })
                  }
                  jobGroup={jobGroup}
                  job={job}
                  refetchFailedStructures={refetchFailedStructures}
                />
              </TabWrapper>
            }
          /> */}
          <Tab
            id="tab_align"
            title="Alignment"
            panel={
              <TabWrapper id="tab_align" activeId={selectedTab}>
                <AlignmentPanel
                  alignmentData={alignmentData}
                  jobGroup={jobGroup}
                  job={job}
                  alignmentDownloadLink={apiServerAddBaseUrl(
                    jobData.links.alignment_file
                  )}
                />
              </TabWrapper>
            }
          />
          <Tab
            id="tab_model"
            title="Probability model"
            panel={
              <TabWrapper id="tab_model" activeId={selectedTab}>
                <ParametersPanel
                  parameterEndpointUrl={apiServerAddBaseUrl(
                    jobData.links.parameters
                  )}
                />
              </TabWrapper>
            }
          />
          <Tab
            id="tab_download"
            title="Downloads"
            panel={
              <TabWrapper id="tab_download" activeId={selectedTab}>
                <DownloadsPanel downloadsData={downloadsData} />
              </TabWrapper>
            }
          />
          {/* <Tabs.Expander /> */}
          {/* {settingsButton} */}
        </Tabs>
        // </div>
      );
    } else {
      // job not finished sucessfully
      if (
        jobData.status === JOB_STATUS.FAIL ||
        jobData.status === JOB_STATUS.TERM ||
        jobData.status === JOB_STATUS.BAILOUT
      ) {
        return <h3>Job failed/was terminated. Cannot display results.</h3>;
      } else {
        // means still running/pending
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Spinner size={Spinner.SIZE_SMALL} />
            <span style={{ marginLeft: "1em" }}>
              <h3>Job has not finished yet.</h3>
            </span>
          </div>
        );
      }
    }
  };

  /*
    TODO: add distance cutoff selection for EC panel
  */
  const renderSettingsDrawer = () => {
    return (
      <Drawer
        isOpen={settingsOpen}
        canEscapeKeyClose={true}
        size={Drawer.SIZE_SMALL}
        title={"Settings"}
        icon="cog"
        onClose={() => setSettingsOpen(false)}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            {selectedTab === "tab_couplings" ? (
              <PanelSelectionSubsettingsPanel
                panels={[
                  {
                    label: "Contact map",
                    state: couplingsShowContactMap,
                    setter: setCouplingsShowContactMap,
                  },
                  {
                    label: "Network",
                    state: couplingsShowNetwork,
                    setter: setCouplingsShowNetwork,
                  },
                  {
                    label: "Table",
                    state: couplingsShowTable,
                    setter: setCouplingsShowTable,
                  },
                  {
                    label: "3D viewer",
                    state: couplingsShowViewer,
                    setter: setCouplingsShowViewer,
                  },
                ]}
              />
            ) : null}

            {selectedTab === "tab_sites" ? (
              <PanelSelectionSubsettingsPanel
                panels={[
                  {
                    label: "Network",
                    state: sitesShowNetwork,
                    setter: setSitesShowNetwork,
                  },
                  {
                    label: "Table",
                    state: sitesShowTable,
                    setter: setSitesShowTable,
                  },
                  {
                    label: "3D viewer",
                    state: sitesShowViewer,
                    setter: setSitesShowViewer,
                  },
                ]}
              />
            ) : null}

            {selectedTab === "tab_mutations" ? (
              <PanelSelectionSubsettingsPanel
                panels={[
                  {
                    label: "Heatmap",
                    state: mutationsShowMatrix,
                    setter: setMutationsShowMatrix,
                  },
                  {
                    label: "3D viewer",
                    state: mutationsShowViewer,
                    setter: setMutationsShowViewer,
                  },
                ]}
              />
            ) : null}

            {selectedTab === "tab_fold" ? (
              <PanelSelectionSubsettingsPanel
                panels={[
                  {
                    label: "Table",
                    state: foldShowTable,
                    setter: setFoldShowTable,
                  },
                  {
                    label: "3D viewer",
                    state: foldShowViewer,
                    setter: setFoldShowViewer,
                  },
                ]}
              />
            ) : null}

            {selectedTab === "tab_sites" || selectedTab === "tab_couplings" ? (
              <PairFilteringSubsettingsPanel
                settings={ecFilters}
                dispatch={dispatchEcFilters}
              />
            ) : null}

            {selectedTab === "tab_couplings" ? (
              <ContactDistanceThresholdSubsettingPanel
                contactDistanceThreshold={contactDistanceThreshold}
                setContactDistanceTreshold={setContactDistanceThreshold}
              />
            ) : null}

            {selectedTab === "tab_mutations" ? (
              <CustomMutationSubsettingsPanel
                targetSequence={targetSequence}
                dispatchMutations={dispatchCustomMutations}
                dispatchNewJobWrapper={(mutations, fileName, errorToaster) => {
                  apiSubmitCustomMutationPrediction(
                    jobData.links.mutations,
                    mutations,
                    (resultRoute) => {
                      dispatchCustomMutations({
                        action: "ADD_JOB",
                        jobGroup: jobGroup,
                        job: job,
                        route: resultRoute,
                        fileName: fileName,
                      });
                    },
                    (error) => {
                      if (errorToaster) {
                        errorToaster(`${fileName}: ${error}`);
                      }
                    }
                  );
                }}
                mutationJobs={customMutations}
                jobGroup={jobGroup}
                job={job}
              />
            ) : null}

            {selectedTab === "tab_sites" ||
            selectedTab === "tab_couplings" ||
            selectedTab === "tab_mutations" ||
            selectedTab === "tab_fold" ? (
              <CustomStructureSubsettingsPanel
                structureStore={userStructures}
                dispatch={dispatchUserStructures}
                targetSequence={targetSequence}
              />
            ) : null}

            {selectedTab === "tab_download" ||
            selectedTab === "tab_model" ||
            selectedTab === "tab_align" ? (
              <div>No settings.</div>
            ) : null}
          </div>
        </div>
      </Drawer>
    );
  };
  // TODO: render job still running case here!
  // TODO: render job crashed case here? or outside?

  // render header and actual job display
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "85vh" }}>
      {renderHeaderDialog()}
      {renderSettingsDrawer()}
      {renderJob()}
    </div>
  );
};
