import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Divider } from "@blueprintjs/core";
import axios from "axios";
import useAxios from "@use-hooks/axios";
import {
  AlignmentViewer,
  FastaAlignment,
  LOGO_TYPES,
  SequenceSorter,
  PositionsToStyle,
  ResidueStyle,
  SequenceBarplotComponent
} from "alignment-viewer-2";

import { ErrorRefetcher, OptionMenu } from "../common/Helpers";
import createPanel from "./Panel";
import "./Alignment.css"; // style overrides for alignment

const DEFAULT_ZOOM_LEVEL = 14;
const MAX_ZOOM_LEVEL = 24;
const MIN_ZOOM_LEVEL = 8;
const ALIGNMENTVIEWER_BASE_URL = "https://projects.sanderlab.org/av/?url="

/*
  Panel for sequence alignment viewer and other alignment-related displays

  TODO: expose zoom level buttons once zooming does not freeze browser
  TODO: add reset button for zoom level
  TODO: turn entropy into conservation bar plot (to be consistent with rest of pipeline and enrichment panel)
*/
export const AlignmentPanel = ({
  alignmentData,
  jobGroup,
  job,
  alignmentDownloadLink,
}) => {
  const viewerLink = ALIGNMENTVIEWER_BASE_URL + encodeURIComponent(alignmentDownloadLink) + "&filename=&darkmode=0&browsefile=0";
  // const link = "http://dev.mutationassessor.org/av/?url=https%3A%2F%2Fapi.evcouplings2.hms.harvard.edu%2Fmonomer%2F12fa03ef45a84a439a6a21021d4aa883%2Fb1.28%2Fdownloads%2Falignment_file";
  const contentFrame = <iframe src={viewerLink} title="AlignmentViewer" style={{flexGrow: 1, border: 0}}></iframe>;
  return createPanel(
    contentFrame,
    {
      width: "95vw",
      maxWidth: "98vw",
      minWidth: "500px",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    false,
  );

  // do not execute loading on first render (when tab mounts), only when user switches to tab
  // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
  // note useAxios has to come before upade of ref!
  const firstUpdate = useRef(true);

  const [sequenceData, setSequenceData] = useState({
    alignment: null,
    error: null,
  });

  const [showMiniMap, setShowMiniMap] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LEVEL);
  const [useLetterLogo, setUseLetterLogo] = useState(true);
  const [sortHamming, setSortHamming] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [showHeaderTrack, setShowHeaderTrack] = useState(true);
  const [style, setStyle] = useState(null);

  const { respose, loading, error, reFetch } = useAxios({
    url: alignmentDownloadLink,
    trigger: [jobGroup, job, firstUpdate.current],
    // only dispatch once tab rerenders and job group/subjob changes
    forceDispatchEffect: () => !firstUpdate.current,
    customHandler: (error, response) => {
      // do not set error if request was aborted due to subjob switch
      if (axios.isCancel(error)) {
        return;
      }

      if (error) {
        setSequenceData({ alignment: null, error: error });
        return;
      }

      // if we received alignment, parse and store it
      if (response && response.data) {
        const alignmentObj = FastaAlignment.fromFileContents(
          "ALIGNMENT_NAME",
          response.data
        );

        // set style if this is first render (but keep later on, e.g. when switching subjobs)
        if (!style) {
          setStyle(alignmentObj.getDefaultStyle());
        }

        setSequenceData({ alignment: alignmentObj, error: null });
      }
    },
  });

  // allow loading once this has been executed once (see useRef above)
  useEffect(() => {
    // console.log("componentDidUpdateFunction", firstUpdate.current);  // TODO: remove
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  // render alignment panel
  let content;

  if (loading) {
    content = null;
  } else {
    if (sequenceData.error) {
      content = (
        <ErrorRefetcher
          refetcher={() => {
            if (reFetch) {
              reFetch();
            }
          }}
        />
      );
    } else if (sequenceData.alignment) {
      const settingsBar = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonGroup minimal={true}>
            {/* <Button
              icon="zoom-in"
              title="Zoom in"
              onClick={() => {
                setZoomLevel(zoomLevel + 1);
              }}
              disabled={zoomLevel >= MAX_ZOOM_LEVEL}
            />
            <Button
              icon="zoom-out"
              title="Zoom out"
              onClick={() => {
                setZoomLevel(zoomLevel - 1);
              }}
              disabled={zoomLevel <= MIN_ZOOM_LEVEL}
            /> 
            // TODO: add a reset button for size too when enabling
            */}

            {/* <Button
              title="Toggle to show/hide logo and bar chart track"
              icon="widget-header"
              active={showHeaderTrack}
              onClick={() => setShowHeaderTrack(!showHeaderTrack)}
            /> */}

            <Button
              title="Hide sequence identifiers"
              icon="menu-closed"
              active={!showAnnotations}
              onClick={() => setShowAnnotations(!showAnnotations)}
            />

            <Button
              title="Toggle bar chart / sequence logo representation of amino acid frequencies per position"
              icon="stacked-chart"
              active={!useLetterLogo}
              onClick={() => setUseLetterLogo(!useLetterLogo)}
            />

            <Button
              title="Show image view of alignment"
              icon="map" // alternative: panel-stats
              active={showMiniMap}
              onClick={() => setShowMiniMap(!showMiniMap)}
            />
  
            <Divider />
            <Button
              title="Sort alignment by hamming distance to target sequence (most similar first)"
              icon="sort-desc"
              active={sortHamming}
              onClick={() => setSortHamming(!sortHamming)}
            />

            <Divider />
            <OptionMenu
              selection={style.alignmentType.allColorSchemes.indexOf(
                style.colorScheme
              )}
              setSelection={(value) =>
                setStyle({
                  ...style,
                  colorScheme: style.alignmentType.allColorSchemes[value],
                })
              }
              options={style.alignmentType.allColorSchemes.map(
                (colorScheme, idx) => ({
                  text: colorScheme.description,
                  value: idx,
                })
              )}
              child={
                <Button title="Select alignment color scheme" icon="tint" />
              }
            />

            <OptionMenu
              selection={style.residueDetail.key}
              setSelection={(value) =>
                setStyle({
                  ...style,
                  residueDetail: ResidueStyle.fromKey(value),
                })
              }
              options={ResidueStyle.list.map((rd, idx) => ({
                text: rd.description,
                value: rd.key,
              }))}
              child={
                <Button title="Select residue styling type" icon="flash" />
              }
            />

            <OptionMenu
              selection={PositionsToStyle.list.indexOf(style.positionsToStyle)}
              setSelection={(value) =>
                setStyle({
                  ...style,
                  positionsToStyle: PositionsToStyle.list[value],
                })
              }
              options={PositionsToStyle.list.map((pts, idx) => ({
                text: pts.description,
                value: idx,
              }))}
              child={
                <Button title="Select positions to style" icon="contrast" />
              }
            />

            <Divider />
            <a
              download
              className={"bp3-button bp3-icon-import bp3-minimal"}
              role="button"
              href={alignmentDownloadLink}
              title="Download alignment file"
            ></a>
          </ButtonGroup>
        </div>
      );

      content = (
        <>
          <div
            style={{
              flexGrow: 1,
              position: "relative",
            }}
          >
            <AlignmentViewer
              alignment={sequenceData.alignment}
              showMinimap={showMiniMap}
              zoomLevel={zoomLevel}
              sortBy={
                sortHamming
                  ? SequenceSorter.HAMMING_DIST_QUERY
                  : SequenceSorter.INPUT
              }
              showAnnotations={showAnnotations}
              logoOptions={{
                logoType: useLetterLogo ? LOGO_TYPES.LETTERS : LOGO_TYPES.BARS,
                height: "80px",
                // tooltipPlacement: "top"
              }}
              minimapOptions={{
                startingWidth: 120,
                verticalHeight: "div",
                alignHorizontal: "right",
                resizable: "horizontal",
              }}
              showConsensus={showHeaderTrack}
              showLogo={showHeaderTrack}
              showQuery={showHeaderTrack}
              showRuler={showHeaderTrack}
              style={style}

              // TODO: create conservation barplot (normalized entropy)
              // TODO: allow to hide barplots using showHeaderTrack
              barplots={[
                {
                  dataSeriesSet: [
                    SequenceBarplotComponent.CONSERVATION_BARPLOT,
                    SequenceBarplotComponent.GAPS_BARPLOT,
                  ],
                  tooltipPlacement: "bottom",
                  height: "80px",
                },
              ]}
            />
          </div>
          {settingsBar}
        </>
      );
    }
  }

  return createPanel(
    content,
    {
      width: "95vw",
      maxWidth: "98vw",
      minWidth: "500px",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    loading
  );
};
